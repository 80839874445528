.sidebar {
  height: 100dvh;
  width: 250px;
  top: 0;
  border-right: 2px solid #ddd;
  transition: width 0.3s ease;
  background-color: #f4f3fc;
  border-top-right-radius: 10px;
  &.sidebar-collapsed {
    width: 80px;
    &.nav-link {
      justify-content: center;
    }
    &.nav-link .me-2 {
      margin-right: 0t;
    }
  }
}

.nav .nav-link:hover,
.nav-link:focus,
.nav-link.active {
  background-color: #7071b2;
}

.nav .nav-link {
  height: 50px;
}

.nav .nav-link .sidebarIcon {
  filter: brightness(0.2) sepia(1) hue-rotate(180deg) saturate(15);
}

.nav .nav-link:hover .sidebarIcon {
  filter: brightness(100) sepia(1) hue-rotate(180deg) saturate(15);
}

.sidebar {
  & .floating-anchor {
    position: absolute;
    right: -12%;
  }

  &.sidebar-collapsed .floating-anchor {
    right: -32%;
  }
}

.sidebar .logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  margin: auto;
  .sidebar-logo {
    width: 85%;
  }
}

.sidebar .sidebar-collapsed .logo-container {
  .sidebar-logo {
    width: 60%;
  }
}

@media screen and (max-width: 500px) {
  .sidebar {
    overflow: hidden;
    height: inherit;
    border-radius: 0;
    & .floating-anchor {
      position: absolute;
      right: 0;
    }

    &.sidebar-collapsed .floating-anchor {
      left: 20%;
    }
  }
  .sidebar .logo-container {
    height: 170px;
  }
}
