.modal {
    --bs-modal-width: 70% !important;
}
.modal .right {
    position: absolute;
    right: 0;
    margin: 0;
    height: 100dvh;
    width: 70% !important;
}

.modal .right .modal-content {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.modal .modal-dialog .modal-content {
    height: 100%;
}

.modal .right .modal-body {
    overflow-y: scroll;
}

@media only screen and (max-width: 500px) {
    .modal .right {
        width: 100% !important;
    }
    .modal .modal-dialog .modal-content {
        height: fit-content !important;
    }
}