.dropzone {
  cursor: pointer;
  transition: border 0.3s ease-in-out;
}

.dropzone-label {
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  background-color: #ffff;
  position: absolute;
  left: 0.8rem;
  top: -0.7rem;
  padding: 0 5px;
}

.dropzone-container {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 20px;
  width: 100%;
  margin: 0 auto;
  max-width: 600px;
}

.preview-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
  margin-top: 20px;
}

.preview-item {
  position: relative;
  width: 100%;
  padding-top: 100%;
  /* 1:1 Aspect Ratio */
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.preview-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.remove-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
}

.remove-button:hover {
  background-color: rgba(255, 0, 0, 0.7);
}
